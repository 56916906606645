import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

const PostArchiveTemplate = ({ data, pageContext }) => {
  const posts = data.allWpPost.nodes
  const { currentPage, numPages } = pageContext

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/blog/" : `/blog/page/${currentPage - 1}`
  const nextPage = `/blog/page/${currentPage + 1}`

  return (
    <Layout>
      <div className="px-4 lg:px-8 space-y-4 lg:space-y-8 py-4 lg:py-24 max-w-4xl mx-auto h-full mt-24">
        {posts.map(post => (
          <FeedPost key={post.id} post={post} />
        ))}
      </div>
    </Layout>
  )
}

export default PostArchiveTemplate

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allWpPost(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip) {
      nodes {
        id
        title
        uri
        excerpt
        date(formatString: "YYYY-MM-DD")
      }
    }
  }
`

const FeedPost = ({ post }) => {
  return (
    <div className="space-y-4">
      <Link
        to={`${post.uri}`}
        className="font-bold underline hover:no-underline"
      >
        <h2 classname="text-xl  text-gray-800">{post.title}</h2>
      </Link>
      <div
        className="text-gray-600"
        dangerouslySetInnerHTML={{ __html: post.excerpt }}
      />
      <p>Publicerat {post.date}</p>
    </div>
  )
}
